import { navigate } from "gatsby"
import { useEffect, useState } from "react"
import tw from "twin.macro"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"

const BadgePage: React.FC<{ badgeId: string }> = ({ badgeId }) => {
  useEffect(() => {

    navigate("/")
  }, [])

  return null
}

export default BadgePage
